import PropTypes from 'prop-types'
import classNames from 'classnames'

import ProductTileSet from '../ProductTileSet/ProductTileSet'

import CATEGORIES from '@/temp-configs/category-constants'

import styles from './ProductTileSection.module.scss'

const ProductTileSection = ({ products, sectionClassname }) => {
    const sectionClassnames = classNames('section', sectionClassname)
    return (
        <section className={sectionClassnames}>
            <div className={'container'}>
                <h2 className="u-marginBottom--2xl t-heading2">
                    Find the right Saatva mattress for&nbsp;you
                </h2>
                <ProductTileSet products={products} />
                <div className="u-marginTop--xl">
                    <a className={`t-link ${styles.viewMoreLink}`} href={`/${CATEGORIES.MATTRESSES}`} target="_self">
                        View More
                    </a>
                </div>
            </div>
        </section>
    )
}

ProductTileSection.propTypes = {
    products: PropTypes.array.isRequired
}

export default ProductTileSection
