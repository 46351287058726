import PropTypes from 'prop-types'
import classNames from 'classnames'

import { useGetDiscounts } from '@saatva-bits/pattern-library.modules.promotions'
import { useBreakpoints } from '@saatva-bits/pattern-library.hooks.use-breakpoints'
import { useProductState } from '@saatva-bits/pattern-library.modules.selection'
import { DetailProductTile } from '@saatva-bits/pattern-library.components.detail-product-tile'
import { handleTag } from '@saatva-bits/pattern-library.modules.detail-modal'
import { useExperiment } from '@saatva-bits/pattern-library.modules.launch-darkly'

import { getDetailTileArdadImages } from '@/utils/product'
import { applyVariantFiltering } from '@/utils/filters'

const HomeProductTile = ({ product, containerClassname }) => {
    const { getDiscounts } = useGetDiscounts()
    const productWithDiscount = applyVariantFiltering([product], [], getDiscounts)[0]
    const {
        isNew,
        tag: productTag,
        url,
        category,
        subcategory,
        productCode,
        title,
        subtitle,
        ardadDescriptors,
        lowestPrice,
        highestPrice,
        lowerPrice,
        upperPrice
    } = productWithDiscount

    const productState = useProductState(productCode, ['assets', 'genericName', 'sku', 'productCode'])
    const { isV1: isFavoriteIconEnabled } = useExperiment('EXP.FAVORITES.DIS-530')


    const priceRange = {
        fullPriceMin: lowestPrice,
        fullPriceMax: highestPrice,
        finalPriceMin: lowerPrice,
        finalPriceMax: upperPrice,
    }

    const { defaultImage, hoverImage } = getDetailTileArdadImages(ardadDescriptors, productState)
    const { defaultImage: thumbnailImage } = getDetailTileArdadImages(ardadDescriptors, productState, '1-1')
    const tag = handleTag(isNew, productTag)

    const containerClassnames = classNames('col col--xs-12 col--sm-8 col--md-6 col--lg-4 u-marginBottom--2xl', containerClassname)

    return (
        <div key={productCode} className={containerClassnames}>
            <DetailProductTile
                title={{ text: title, href: url }}
                subtitle={{ text: subtitle }}
                priceRange={priceRange}
                imageHref={url}
                affirm={{ price: lowerPrice }}
                defaultImage={defaultImage}
                hoverImage={hoverImage}
                imageBadgeLeft={tag && { text: tag }}
                productCode={productCode}
                location='Home Page Product Tile'
                enableFavorites={isFavoriteIconEnabled}
                category={category}
                subCategory={subcategory}
                thumbnailImage={thumbnailImage}
            />
        </div>
    )
}

const ProductTileSet = ({ products, containerClassname, productTileClassname, containerSelector }) => {
    const { isMobile } = useBreakpoints('desktop')

    if (!products) {
        return null
    }

    const productList = isMobile ? products.slice(0, 3) : products

    const containerClassnames = classNames('row u-flexJustify--center', containerClassname)

    return (
        <div className={containerClassnames} data-selector={containerSelector}>
            {productList.map(product => (
                <HomeProductTile key={product.productCode} product={product} containerClassname={productTileClassname}/>
            ))}
        </div>
    )
}

ProductTileSet.propTypes = {
    products: PropTypes.array,
    containerClassname: PropTypes.string,
}

export default ProductTileSet
